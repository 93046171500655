import api from "../api";
const ensureString = (value) =>
  typeof value !== "string" ? String(value) : value;

export const trackEvent = async (distinct_id, eventName, properties = {}) => {
  distinct_id = ensureString(distinct_id);

  try {
    await api.post("/api/mixpanel/track", {
      distinct_id,
      event: eventName,
      properties,
    });
    // console.log('Event tracked:', eventName);
  } catch (error) {
    // console.error('Error tracking event:', error);
  }
};

export const identifyUser = async (distinct_id, properties = {}) => {
  distinct_id = ensureString(distinct_id);

  try {
    await api.post("/api/mixpanel/identify", {
      distinct_id,
      properties,
    });
    // console.log('User identified:', distinct_id);
  } catch (error) {
    // console.error('Error identifying user:', error);
  }
};
