import React, { useState } from "react";
import { useMixpanelTracking } from '../hooks/useMixpanelTracking';
import { useNavigate, useLocation } from "react-router-dom";
import universeIcon from "../assets/icons/universe.png";
import businessIcon from "../assets/icons/business.png";
import commericalsIcon from "../assets/icons/commercials.png";
import contentIcon from "../assets/icons/content.png";
import inventoryIcon from "../assets/icons/inventory.png";
import purchaseIcon from "../assets/icons/purchase.png";
import approvalsIcon from "../assets/icons/approvals.png";
// import socialIcon from "../assets/icons/social.png";
import marketingIcon from "../assets/icons/marketing.png";
import logo from "../assets/logo.png";

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { trackEvent } = useMixpanelTracking();

  const menuItems = [
    { name: "Universe", icon: universeIcon, path: "/" },
    { name: "Biz Summary", icon: businessIcon, path: "/business" },
    { name: "Approvals", icon: approvalsIcon, path: "/approvals" },
    { name: "Commercials", icon: commericalsIcon, path: "/commercials" },
    { name: "Content", icon: contentIcon, path: "/content" },
    { name: "Inventory", icon: inventoryIcon, path: "/inventory" },
    { name: "Purchase Order", icon: purchaseIcon, path: "/purchase" },
    { name: "Marketing", icon: marketingIcon, path: "/marketing" },
  ];

  const handleItemClick = (item) => {
    trackEvent("Sidebar Item Clicked", {
      itemName: item.name,
      currentPage: location.pathname,
    });
    navigate(item.path);
  };

  const toggleSidebar = () => {
    trackEvent("Sidebar Toggled", { isCollapsed: !isCollapsed });
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div
      className={`bg-[#133243] text-gray-100 h-screen flex flex-col justify-between 
        ${isCollapsed ? "w-16" : "w-48"} 
        transition-[width] duration-300 
        whitespace-nowrap overflow-hidden pt-8`}
    >
      <div>
        {/* Logo */}
        <div
          className={`flex items-center mb-4 ${
            isCollapsed ? "justify-center" : "justify-start pl-2"
          }`}
        >
          <img
            src={logo}
            alt="Logo"
            className={`transition-[width] duration-300 ${
              isCollapsed ? "w-10 h-10" : "w-16 h-16"
            }`}
          />
        </div>

        {/* Header */}
        {!isCollapsed && (
          <div className="px-4 pl-2 py-2 mb-4 border-b">
            <h2 className="text-2xl font-medium">Dashboard</h2>
          </div>
        )}

        {/* Menu Items */}
        <nav className="px-2">
          {menuItems.map((item) => (
            <button
              key={item.name}
              onClick={() => handleItemClick(item)}
              className={`flex items-center p-2 rounded-md mb-2 w-full
                ${
                  location.pathname === item.path
                    ? "bg-[#029E9B]"
                    : "hover:bg-gray-700"
                } 
                ${isCollapsed ? "justify-center" : "justify-start"} 
                whitespace-nowrap`}
            >
              <img
                src={item.icon}
                alt={`${item.name} icon`}
                className="w-6 h-6 flex-shrink-0"
              />
              {!isCollapsed && (
                <span className="ml-3">{item.name}</span>
              )}
            </button>
          ))}
        </nav>
      </div>

      {/* Bottom Toggle Button */}
      <div className="flex justify-center border-t px-0">
        <button onClick={toggleSidebar} className="cursor-pointer p-2">
          {isCollapsed ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path fill="#fff" d="M12.6 12L8 7.4L9.4 6l6 6l-6 6L8 16.6z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
            >
              <path fill="#fff" d="m14 18l-6-6l6-6l1.4 1.4l-4.6 4.6l4.6 4.6z" />
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
