import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import PostCard from "../components/PostCard";
import { UserContext } from "../context/UserContext";
import { InformationCircleIcon } from "@heroicons/react/24/outline"; // Importing an icon

const Marketing = () => {
  const { brandId } = useContext(UserContext);
  // console.log(brandId)
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  // Static fallback data
  const demoData = [
    {
      id: 1,
      store: "Arogya Organic",
      time: "09:00 AM",
      date: "21 Dec' 2024",
      image:
        "https://omspics.s3.ap-southeast-1.amazonaws.com/1920/poPBAf6Xgm0X082ixLqt1727757782373FFtd.jpeg",
      postedBy: "Ravindra Kumar Singh",
    },
    {
      id: 2,
      store: "Top In Town Hypermarket",
      time: "09:00 AM",
      date: "21 Dec' 2024",
      image:
        "https://omspics.s3.ap-southeast-1.amazonaws.com/1920/uDbdq9HOaT2exILCGksa1727759430606Kdhs.jpeg",
      postedBy: "Santosh Pujari",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (!brandId) {
        console.warn("Brand ID is not available.");
        setPosts([]);
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const response = await axios.get(`/api/brands/${brandId}`);
        if (Array.isArray(response.data)) {
          setPosts(response.data);
        } else if (response.data.message) {
          console.info(response.data.message); // Log the message for debugging
          setPosts([]); // Set posts to an empty array if no data is found
        } else {
          console.error("Unexpected response format:", response.data);
          setPosts([]);
        }
      } catch (error) {
        console.error("Error fetching marketing data:", error.message || error);
        setPosts([]); // Clear posts on error
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [brandId]);

  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsImageModalOpen(true);
  };

  return (
    <div className="p-6 bg-white flex-1 h-full flex flex-col">
      {/* Fixed Header Section */}
      <div className="flex-shrink-0">
        <h1 className="text-2xl font-bold text-gray-800">Marketing</h1>

        {/* Conditional "Demo Data" Heading */}
        {posts.length === 0 && (
          <div className="flex items-center mt-2 p-2 bg-pink-50 text-pink-700 rounded-lg text-sm font-medium shadow-sm">
            <InformationCircleIcon className="h-5 w-5 mr-2" />
            Demo Data
          </div>
        )}

        <div className="flex items-center justify-between mt-4">
          {/* Removed the commented out "Recent" heading */}
          <div className="flex-1 border-b-2 border-gray-200"></div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center flex-1">
          <p className="text-center text-gray-600">Loading...</p>
        </div>
      ) : (
        <div className="space-y-6 mt-4 flex-1 overflow-auto text-left">
          {Array.isArray(posts) && posts.length === 0
            ? demoData.map((post) => (
                <PostCard
                  key={post.id}
                  post={post}
                  onImageClick={() => openImageModal(post.image)}
                />
              ))
            : Array.isArray(posts) &&
              posts.map((post) => (
                <PostCard
                  key={post.id}
                  post={post}
                  onImageClick={() => openImageModal(post.image)}
                />
              ))}
        </div>
      )}

      {/* Image Modal */}
      {isImageModalOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-80 flex items-center justify-center">
          <div className="relative">
            <button
              onClick={() => setIsImageModalOpen(false)}
              className="absolute top-4 right-4 text-white bg-red-500 rounded-full p-2 hover:bg-red-600 focus:outline-none"
            >
              ✕
            </button>
            <img
              src={selectedImage}
              alt="Enlarged Post"
              className="max-w-full max-h-screen rounded-lg"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Marketing;
