/* global google */
import React, { useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: 12.9716, // Default latitude (Bangalore)
  lng: 77.5946, // Default longitude
};

const MapComponent = ({ selectedDate, setEventDates, filters, events }) => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const infoWindowRef = useRef(null);

  // Initialize the Google Maps API and map
  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyCgU8ok_12P2PGbSmGKFdRyFyRQF0FNIU8",
      version: "weekly",
      libraries: ["places"],
    });

    loader
      .load()
      .then(() => {
        if (mapRef.current && !mapInstanceRef.current) {
          mapInstanceRef.current = new google.maps.Map(mapRef.current, {
            center,
            zoom: 12,
            mapId: "cff9b68ed09c5011",
          });
          infoWindowRef.current = new google.maps.InfoWindow();
        }
      })
      .catch((error) => {
        console.error("Error loading Google Maps:", error);
      });
  }, []);

  // Whenever filters, selectedDate, or events change, update markers
  useEffect(() => {
    if (!mapInstanceRef.current) return;

    // Clear previous markers
    if (mapInstanceRef.current.markers) {
      mapInstanceRef.current.markers.forEach((marker) => marker.setMap(null));
    }

    // Filter events based on selectedDate and filters
    const filteredEvents = events.filter((event) => {
      const eventDate = new Date(event.date);
      const isUpcoming = eventDate > new Date();
      const isPast = eventDate < new Date();
      const isSelectedDate =
        selectedDate &&
        eventDate.getDate() === selectedDate.getDate() &&
        eventDate.getMonth() === selectedDate.getMonth() &&
        eventDate.getFullYear() === selectedDate.getFullYear();

      return (
        ((filters.showUpcoming && isUpcoming) ||
          (filters.showPast && isPast)) &&
        (!selectedDate || isSelectedDate)
      );
    });

    const markers = filteredEvents.map((event) => {
      const { latitude, longitude } = event.eventHotspot ?? center;

      // Check if lat/long are valid numbers
      if (isNaN(latitude) || isNaN(longitude)) {
        console.error("Invalid lat/long values:", latitude, longitude);
        return null;
      }

      const marker = new google.maps.Marker({
        position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
        map: mapInstanceRef.current,
        title: event.event_name,
      });

      marker.addListener("click", () => {
        // Check the event data in the console
        console.log(event);

        infoWindowRef.current.setContent(`
          <div style="font-family: Arial, sans-serif; color: #029E9B; max-width:300px;">
            <h2 style="font-size:14px; font-weight:normal; border-bottom:1px solid #E1E0E0; margin-bottom:8px;">
              ${event.eventHotspot?.property_name ?? "No Property Name"}, 
              ${event.eventHotspot?.property_address ?? "No Address"}
            </h2>
            <div style="padding-top:8px; color:#6A6B6B;">
            <p><span class="font-bold">Event:</span> <strong>${
              event.event_name
            }</strong></p>
              <p><span class="font-bold">Time:</span> <strong>${
                event.start_time
              } - ${event.end_time}</strong></p>
              <p><span class="font-bold">Schedule: </span> <strong>${new Date(
                event.date
              ).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}</strong></p>
            </div>
            <a href="https://maps.google.com/?q=${
              event.eventHotspot?.latitude
            },${
          event.eventHotspot?.longitude
        }" target="_blank" rel="noopener noreferrer" style="color:#FF9519; font-weight:300; text-decoration:underline; display:inline-block; margin-top:8px;">
              Check out the address 
              <img style="display:inline-block; vertical-align:middle;" src="images/Vector-ArrowRight.svg" alt="Arrow Right">
            </a>
          </div>
        `);
        infoWindowRef.current.open(mapInstanceRef.current, marker);
      });

      return marker;
    });

    // Filter out null markers (invalid lat/long) and store the valid ones
    mapInstanceRef.current.markers = markers.filter(
      (marker) => marker !== null
    );
  }, [selectedDate, filters, events]);

  return <div ref={mapRef} style={mapContainerStyle}></div>;
};

export default MapComponent;
