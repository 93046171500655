import React, { createContext, useReducer, useEffect } from "react";
import api from "../api";
import { useAuth } from "./AuthContext";

export const UserContext = createContext();

const initialState = {
  brand: null,
  loading: false,
  error: null,
};

const userReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_START":
      return { ...state, loading: true, error: null };
    case "FETCH_SUCCESS":
      return { ...state, loading: false, brand: action.payload, error: null };
    case "FETCH_ERROR":
      return { ...state, loading: false, error: action.payload };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const { isAuthenticated, logout } = useAuth();

  const fetchUser = async () => {
    if (!isAuthenticated) {
      dispatch({ type: "CLEAR" });
      return;
    }

    dispatch({ type: "FETCH_START" });

    try {
      const response = await api.get("/api/users");
      // console.log(response.data.user);
      if (response.data?.user) {
        dispatch({ type: "FETCH_SUCCESS", payload: response.data.user });
      } else {
        dispatch({ type: "FETCH_ERROR", payload: "User not found" });
        logout();
      }
    } catch (error) {
      console.error("Error fetching user:", error);
      dispatch({ type: "FETCH_ERROR", payload: "Failed to fetch user data" });
      logout();
    }
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const isAdmin = state.brand?.isAdmin || false;

  return (
    <UserContext.Provider
      value={{
        ...state,
        brandId: state.brand?.brand_id || null,
        userID: state.brand?.id || null,
        userName: state.brand?.name || null,
        userEmail: state.brand?.email || null,
        isAdmin,
        fetchUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
