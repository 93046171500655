const PostCard = ({ post, onImageClick }) => (
  <div className="border border-gray-200 rounded-lg shadow-md max-w-3xl text-left">
    <div className="p-4 flex items-start space-x-4">
      {/* Store Icon */}
      <div className="flex-1">
        <div className="flex flex-row border-b-2 border-[#FCFBFB] pb-1">
          <span className="text-orange-500 text-lg font-bold">
            <span role="img" aria-label="store">
              <svg
                width="25"
                height="25"
                viewBox="0 0 27 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.31489 1.57733C1 2.19556 1 3.00444 1 4.62222V5.50911L26.9856 3.146C26.9581 2.44111 26.8844 1.97022 26.6851 1.57733C26.4081 1.03378 25.9662 0.591852 25.4227 0.314889C24.8044 -8.9407e-08 23.9956 0 22.3778 0H5.62222C4.00444 0 3.19556 -8.9407e-08 2.57733 0.314889C2.03378 0.591852 1.59185 1.03378 1.31489 1.57733ZM27 6.04644L19.9728 6.68489L23.5926 25.9928C24.4477 25.974 24.9836 25.909 25.4227 25.6851C25.9662 25.4081 26.4081 24.9662 26.6851 24.4227C27 23.8044 27 22.9956 27 21.3778V6.04644ZM20.656 26L17.0824 6.94778L1 8.40955V21.3778C1 22.9956 1 23.8044 1.31489 24.4227C1.59185 24.9662 2.03378 25.4081 2.57733 25.6851C3.19556 26 4.00444 26 5.62222 26H20.656ZM14.7222 17.3767C14.7222 20.4938 11.6311 22.6836 10.2834 23.491C10.0971 23.6028 9.88394 23.6618 9.66667 23.6618C9.44939 23.6618 9.2362 23.6028 9.04989 23.491C7.70222 22.6836 4.61111 20.4938 4.61111 17.3752C4.61111 14.3173 7.06089 12.2778 9.66667 12.2778C12.3634 12.2778 14.7222 14.3173 14.7222 17.3767Z"
                  fill="#E75504"
                />
                <path
                  d="M9.5 20C10.8807 20 12 18.8807 12 17.5C12 16.1193 10.8807 15 9.5 15C8.11929 15 7 16.1193 7 17.5C7 18.8807 8.11929 20 9.5 20Z"
                  fill="#E75504"
                />
              </svg>
            </span>
          </span>
          <h2 className="text-xl font-semibold text-gray-800 pl-2">
            {post.store}
          </h2>
        </div>

        <div className="flex space-x-2 mt-2">
          <span className="px-2 py-1 text-sm font-semibold bg-[#CFE6E5] text-[#04807E] rounded">
            {post.time}
          </span>
          <span className="px-2 py-1 text-sm font-semibold bg-[#CFE6E5] text-[#04807E] rounded">
            {post.date}
          </span>
        </div>
      </div>
    </div>
    {/* Image */}
    <img
      src={post.image}
      alt="Marketing"
      className="w-full h-64 object-cover cursor-pointer"
      onClick={onImageClick}
    />
    <div className="p-4 text-sm text-gray-700">
      <span className="font-semibold bg-[#F8F9FA] rounded-lg p-2">
        <span className="font-bold text-[#173A4D]">Posted by:</span>{" "}
        {post.postedBy}
      </span>
    </div>
  </div>
);

export default PostCard;
