import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { FaBars, FaTimes } from "react-icons/fa";
import MapComponent from "../components/MapComponent";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../api";

const EventPage = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [eventDates, setEventDates] = useState([]);
  const [filters, setFilters] = useState({
    showUpcoming: true,
    showPast: false,
  });
  const [events, setEvents] = useState([]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await api.get(`/api/events`);
        const eventData = response.data.data;
        setEvents(eventData);

        // Extract event dates
        const dates = eventData.map((event) => new Date(event.date));
        setEventDates(dates);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const isEventDate = (date) => {
    return eventDates.some(
      (eventDate) =>
        eventDate.getDate() === date.getDate() &&
        eventDate.getMonth() === date.getMonth() &&
        eventDate.getFullYear() === date.getFullYear()
    );
  };

  const resetFilters = () => {
    setFilters({ showUpcoming: true, showPast: false });
    setSelectedDate(null);
  };

  return (
    <div className="flex flex-col h-screen w-screen">
      {/* Global styles for the custom calendar */}
      <style jsx global>{`
        .custom-calendar {
          width: 242px; /* Set a fixed width that looks good */
          margin: 0 auto; /* Center the calendar horizontally */
        }
      `}</style>

      {/* Navbar Section */}
      <nav className="bg-white shadow-md flex items-center justify-between p-4 md:p-6">
        <div className="flex items-center">
          {/* Hamburger Menu for Mobile */}
          <button
            className="md:hidden mr-4 focus:outline-none"
            onClick={() => setIsSidebarOpen(true)}
            aria-label="Open sidebar"
          >
            <FaBars className="h-6 w-6 text-gray-700" />
          </button>
          <div className="text-2xl md:text-4xl font-semibold text-black">
            Check out our <span className="text-[#029E9B]">Event Calendar</span>
          </div>
        </div>
      </nav>

      {/* Mobile Sidebar Modal */}
      <Dialog
        open={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        className="fixed inset-0 z-50 md:hidden"
      >
        <div className="fixed inset-y-0 left-0 w-3/4 bg-white p-4 shadow-lg overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold text-[#029E9B]">Filters</h2>
            <button
              onClick={() => setIsSidebarOpen(false)}
              className="focus:outline-none"
              aria-label="Close sidebar"
            >
              <FaTimes className="h-6 w-6 text-gray-700" />
            </button>
          </div>

          {/* Filters Content */}
          <div className="space-y-6">
            {/* Schedule Filter */}
            <div className="space-y-2">
              <h4 className="text-lg font-semibold">Schedule</h4>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.showUpcoming}
                  onChange={() =>
                    setFilters((prev) => ({
                      showUpcoming: !prev.showUpcoming,
                      showPast: prev.showUpcoming ? false : prev.showPast,
                    }))
                  }
                  className="mr-2"
                />
                Upcoming Events
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.showPast}
                  onChange={() =>
                    setFilters((prev) => ({
                      showUpcoming: prev.showPast ? false : prev.showUpcoming,
                      showPast: !prev.showPast,
                    }))
                  }
                  className="mr-2"
                />
                Past Events
              </label>
            </div>

            {/* Date Picker (Mobile) */}
            <div className="space-y-2">
              <h4 className="text-lg font-semibold">Check by Dates</h4>
              <button
                onClick={() => setIsDatePickerOpen(true)}
                className="w-full bg-gray-200 py-2 rounded-md text-left px-3 focus:outline-none"
              >
                {selectedDate
                  ? selectedDate.toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "long",
                      year: "numeric",
                    })
                  : "Select a date"}
              </button>
            </div>

            {/* Reset Filters Button */}
            <button
              onClick={() => {
                resetFilters();
                setIsSidebarOpen(false);
              }}
              className="w-full bg-gray-200 text-gray-700 py-2 rounded-3xl hover:bg-gray-300 transition duration-300"
            >
              Reset Filters
            </button>
          </div>
        </div>
      </Dialog>

      {/* Mobile Date Picker Modal */}
      <Dialog
        open={isDatePickerOpen}
        onClose={() => setIsDatePickerOpen(false)}
        className="fixed inset-0 z-50 flex items-center justify-center p-4"
      >
        <div className="bg-white p-4 rounded-lg shadow-lg max-h-full overflow-y-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">Select a Date</h2>
            <button
              onClick={() => setIsDatePickerOpen(false)}
              className="focus:outline-none"
              aria-label="Close date picker"
            >
              <FaTimes className="h-6 w-6 text-gray-700" />
            </button>
          </div>
          <div className="custom-calendar">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              inline
              highlightDates={eventDates}
              dayClassName={(date) =>
                isEventDate(date) ? "highlighted-event-date" : undefined
              }
              calendarClassName="custom-calendar"
            />
          </div>
        </div>
      </Dialog>

      {/* Main Content */}
      <div className="flex flex-grow">
        {/* Sidebar for Desktop */}
        <aside className="hidden md:block w-[310px] bg-gray-100 p-4 shadow-lg overflow-y-auto">
          <div className="border bg-white p-4 space-y-6 h-full flex flex-col">
            <h2 className="text-xl font-bold text-[#029E9B] border-b border-[#029E9B] pb-2">
              Filters
            </h2>

            {/* Schedule Filter */}
            <div className="space-y-2">
              <h4 className="text-lg font-semibold">Schedule</h4>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.showUpcoming}
                  onChange={() =>
                    setFilters((prev) => ({
                      showUpcoming: !prev.showUpcoming,
                      showPast: prev.showUpcoming ? false : prev.showPast,
                    }))
                  }
                  className="mr-2"
                />
                Upcoming Events
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={filters.showPast}
                  onChange={() =>
                    setFilters((prev) => ({
                      showUpcoming: prev.showPast ? false : prev.showUpcoming,
                      showPast: !prev.showPast,
                    }))
                  }
                  className="mr-2"
                />
                Past Events
              </label>
            </div>

            {/* Date Picker (Desktop) */}
            <div className="flex flex-col items-center justify-center">
              <h4 className="text-lg font-semibold">Check by Dates</h4>
              {/* Wrap the DatePicker to ensure it stays centered */}
              <div className="custom-calendar">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  inline
                  highlightDates={eventDates}
                  dayClassName={(date) =>
                    isEventDate(date) ? "highlighted-event-date" : undefined
                  }
                />
              </div>
            </div>

            {/* Reset Filters Button */}
            <div>
              <button
                onClick={resetFilters}
                className="w-full bg-gray-200 text-gray-700 py-2 rounded-3xl hover:bg-gray-300 transition duration-300"
              >
                Reset Filters
              </button>
            </div>
          </div>
        </aside>

        {/* Map Section */}
        <div className="flex-1 p-4">
          <MapComponent
            selectedDate={selectedDate}
            setEventDates={setEventDates}
            filters={filters}
            events={events}
          />
        </div>
      </div>
    </div>
  );
};

export default EventPage;
